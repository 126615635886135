.sidebar {
  min-height: 100vh;
  overflow-y: scroll;
}

.sidenav-header-logo {
  max-height: 32px;
}

.sidebar-link.active,
.sidebar-link.active i {
  color: #fff;
}
