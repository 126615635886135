.date-min-width {
  min-width: 190px;
}

table {
  position: relative;
  border-collapse: collapse;
}

thead tr th {
  position: sticky;
  vertical-align: top !important;
  white-space: nowrap;
  z-index: 500;
  background-color: #fff;
}

thead tr:first-child th {
  top: -10px;
}

thead tr:nth-child(2) th {
  top: 30px;
}

.table-responsive,
.table-fixed {
  min-height: 150px;
}

.table-fixed {
  overflow-x: scroll;
}
