.signin-logo {
  height: 64px;
  width: 64px;
}

.signin {
  background-image: url('background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  padding-top: 15%;
}
